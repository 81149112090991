import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import HackavizContest from '../../components/HackavizContest';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HackavizContest startDate={new Date('2024-03-08')} endDate={new Date('2024-03-18')} tallyFormUrl={'tally.so/r/mKl11A'} partyDate={new Date('2024-04-04')} mdxType="HackavizContest" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      